import { isEmpty } from "ramda";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";

import { LoadingOutlined } from "@ant-design/icons";
import { Button, Image, message, Space, Spin, Typography } from "antd";

// import css
import styles from "./Room.module.less";

// image

import { useTranslation } from "react-i18next";

// import component
import Glyph from "../../../Common/Glyph/Glyph";
import SettingsBlind from "../SettingsUI/SettingsBlind";
import SettingsDegrees from "../SettingsUI/SettingsDegrees";
import SettingsFanSpeed from "../SettingsUI/SettingsFanSpeed";
import SettingsLight from "../SettingsUI/SettingsLight";

// import redux
import {
  getComfortAcFanSpeed,
  getComfortAcOffset,
  getComfortBlinds,
  getComfortBlindsAngle,
  getComfortLighting,
  setModeAuto,
} from "../../../../services/redux/services/ConfortWS";
import useMap from "../../../Common/Map/useMap";

const Room = ({ isAuto, setIsAuto, selectedRoom, setIsZone, setEquipment }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { selectedOnMap } = useMap();
  const room = selectedRoom || selectedOnMap;

  const [isLoadingBlind, setIsLoadingBlind] = useState(false);
  const [isLoadingLight, setIsLoadingLight] = useState(false);
  const [isLoadingHeat, setIsLoadingHeat] = useState(false);
  const [isLoadingFanSpeed, setIsLoadingFanSpeed] = useState(false);

  useEffect(() => {
    if (selectedOnMap) history.push(`/smartControl/${selectedOnMap.id}`);
  }, [history, selectedOnMap]);

  useEffect(() => {
    if (room) {
      if (room?.features?.comfort?.blind?.options?.includes("dimmer")) {
        setIsLoadingBlind(true);
      }
      if (room?.features?.comfort?.light?.options?.includes("dimmer")) {
        setIsLoadingLight(true);
      }

      setIsLoadingHeat(true);
      setIsLoadingFanSpeed(true);
      Promise.allSettled([
        room?.features?.comfort?.ac?.options?.includes("heatOffset")
          ? dispatch(getComfortAcOffset({ roomId: room.id }))
              .unwrap()
              .finally(() => setIsLoadingHeat(false))
          : null,
        room?.features?.comfort?.ac?.options?.includes("fanSpeed")
          ? dispatch(getComfortAcFanSpeed({ roomId: room.id }))
              .unwrap()
              .finally(() => setIsLoadingFanSpeed(false))
          : null,
        room?.features?.comfort?.light?.options?.includes("dimmer")
          ? dispatch(getComfortLighting({ roomId: room.id, zone: 0 }))
              .unwrap()
              .finally(() => setIsLoadingLight(false))
          : null,
        room?.features?.comfort?.blind?.options?.includes("dimmer")
          ? dispatch(getComfortBlinds({ roomId: room.id, zone: 0 }))
              .unwrap()
              .finally(() => setIsLoadingBlind(false))
          : null,
        room?.features?.comfort?.blind?.options?.includes("tilt")
          ? dispatch(getComfortBlindsAngle({ roomId: room.id, zone: 0 })).unwrap()
          : null,
      ]).then((results) => {
        if (results.find((r) => r.status === "rejected")) {
          message.error(t("ErrorGetComfort"), 5);
        }
      });
    }
  }, [dispatch, room, t]);

  const modeAuto = () => {
    if (room?.features?.comfort?.auto?.enbaled === true) {
      setIsAuto(true);

      dispatch(setModeAuto({ roomId: room.id }))
        .unwrap()
        .catch(() => {
          message.error(t("ErrorModeAuto"), 5);
        });
    }
  };

  return (
    <>
      <div className={styles["Room__info"]}>
        {isEmpty(room?.photos) ? null : (
          <div className={styles["Room__info__picture"]}>
            <Image preview={false} src={room?.photos[0]} />
          </div>
        )}
        {room?.features?.comfort?.auto?.enbaled === true && (
          <Button
            className={styles["Room__info__auto"]}
            icon={
              isAuto ? (
                <Glyph name="check_circle" className={styles["Room__info__auto__check"]} />
              ) : (
                <Glyph name="auto_fix_high" />
              )
            }
            onClick={modeAuto}
          >
            Mode AUTO
          </Button>
        )}
      </div>

      <Space
        style={{
          width: "100%",
          marginTop: "10px",
          paddingBottom: "20px",
          height: "400px",
          overflow: "auto",
        }}
        direction="vertical"
        size="small"
      >
        {room?.features?.comfort?.light && (
          <Spin
            spinning={isLoadingLight}
            indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
          >
            <SettingsLight
              comfort={room?.features?.comfort}
              isAuto={isAuto}
              setIsAuto={setIsAuto}
              extra={
                process.env.REACT_APP_ID_MAP !== "-" ? (
                  <NavLink
                    to={{
                      pathname: `/smartControl/${room?.id}/settings`,
                      search: "?equipement=Light",
                    }}
                    className={styles["Room__navLink"]}
                  >
                    {room?.features?.comfort?.light?.zones?.length} zone(s)
                    <Glyph name="arrow_forward_ios" />
                  </NavLink>
                ) : (
                  room?.features?.comfort?.light?.zones?.length !== 0 && (
                    <div
                      onClick={() => {
                        setIsZone(true);
                        setEquipment("Light");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {room?.features?.comfort?.light?.zones?.length} zone(s)
                      <Glyph name="arrow_forward_ios" />
                    </div>
                  )
                )
              }
              selectedRoom={room}
            />
          </Spin>
        )}

        {room?.features?.comfort?.blind && (
          <Spin
            spinning={isLoadingBlind}
            indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
          >
            <SettingsBlind
              comfort={room?.features?.comfort}
              isAuto={isAuto}
              setIsAuto={setIsAuto}
              extra={
                process.env.REACT_APP_ID_MAP !== "-" ? (
                  <NavLink
                    to={{
                      pathname: `/smartControl/${room.id}/settings`,
                      search: "?equipement=Blind",
                    }}
                    className={styles["Room__navLink"]}
                  >
                    {room?.features?.comfort?.blind?.zones?.length} zone(s)
                    <Glyph name="arrow_forward_ios" />
                  </NavLink>
                ) : (
                  room?.features?.comfort?.blind?.zones?.length !== 0 && (
                    <div
                      onClick={() => {
                        setIsZone(true);
                        setEquipment("Blind");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {room?.features?.comfort?.blind?.zones?.length} zone(s)
                      <Glyph name="arrow_forward_ios" />
                    </div>
                  )
                )
              }
              selectedRoom={room}
            />
          </Spin>
        )}

        {room?.features?.comfort?.ac?.options?.includes("heatOffset") && (
          <Spin
            spinning={isLoadingHeat}
            indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
          >
            <SettingsDegrees
              extra={
                process.env.REACT_APP_ID_MAP !== "-" ? (
                  <NavLink
                    to={{
                      pathname: `/smartControl/${room.id}/settings`,
                      search: "?equipement=Temperature",
                    }}
                    className={styles["Room__navLink"]}
                  >
                    {room?.features?.comfort?.ac?.zones?.length} zone(s)
                    <Glyph name="arrow_forward_ios" />
                  </NavLink>
                ) : (
                  room?.features?.comfort?.ac?.zones?.length !== 0 && (
                    <div
                      onClick={() => {
                        setIsZone(true);
                        setEquipment("Temperature");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {room?.features?.comfort?.ac?.zones?.length} zone(s)
                      <Glyph name="arrow_forward_ios" />
                    </div>
                  )
                )
              }
              comfort={room?.features?.comfort}
              setIsAuto={setIsAuto}
              selectedRoom={room}
            />
          </Spin>
        )}

        {room?.features?.comfort?.ac?.options?.includes("fanSpeed") && (
          <Spin
            spinning={isLoadingFanSpeed}
            indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
          >
            <SettingsFanSpeed
              comfort={room?.features?.comfort}
              isAuto={isAuto}
              setIsAuto={setIsAuto}
              extra={
                process.env.REACT_APP_ID_MAP !== "-" ? (
                  <NavLink
                    to={{
                      pathname: `/smartControl/${room.id}/settings`,
                      search: "?equipement=Ventilation",
                    }}
                    className={styles["Room__navLink"]}
                  >
                    {room?.features?.comfort?.ac?.zones?.length} zone(s)
                    <Glyph name="arrow_forward_ios" />
                  </NavLink>
                ) : (
                  room?.features?.comfort?.ac?.zones?.length !== 0 && (
                    <div
                      onClick={() => {
                        setIsZone(true);
                        setEquipment("Ventilation");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {room?.features?.comfort?.ac?.zones?.length} zone(s)
                      <Glyph name="arrow_forward_ios" />
                    </div>
                  )
                )
              }
              selectedRoom={room}
            />
          </Spin>
        )}

        {!room?.features?.comfort && (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Typography.Text className={styles["primary"]}>
              {t("comfort.ComfortIsNot")}
              <br />
              {t("comfort.availableForThisZone")}
            </Typography.Text>
          </div>
        )}
      </Space>
    </>
  );
};

export default Room;
