import { Button, message, Segmented } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

// import redux
import { setLighting } from "../../../../../services/redux/services/ConfortWS";
import useSelectedRoom from "../../../../../services/redux/useSelectedRoom";

// import component
import Glyph from "../../../../Common/Glyph/Glyph";

// import css
import styles from "../SettingsUI.module.less";
// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../../services/api/Firebase/Firebase";

import { useTranslation } from "react-i18next";

const LightUpDown = ({ setIsLoading, setStatus, zone, selectedRoomNomap }) => {
  const { t } = useTranslation();
  const selectedRoom = useSelectedRoom();
  const dispatch = useDispatch();

  const [value, setValue] = useState(null);

  const dispatchLight = (command) => {
    logEvent(analytics, "smart_control_command_light", {
      zone: zone !== undefined ? "zone" : "total",
      type_light: "up&down",
    });
    setIsLoading(true);
    dispatch(
      setLighting({
        roomId: selectedRoomNomap?.id || selectedRoom.id,
        zone: zone ? parseInt(zone.id) : 0,
        command: command,
      }),
    )
      .unwrap()
      .then(() => {
        setStatus("success");
        setTimeout(() => {
          setStatus(null);
        }, 2000);
      })
      .catch(() => {
        message.error(t("ErrorSetLight"), 5);
        setStatus("error");
        setTimeout(() => {
          setStatus(null);
        }, 2000);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const LightOnOff = (value) => {
    const command = value === "Allumé" ? "L_ON" : "L_OFF";
    setIsLoading(true);
    logEvent(analytics, "smart_control_command_light", {
      zone: zone !== undefined ? "zone" : "total",
      type_light: "up&down",
    });
    dispatch(
      setLighting({
        roomId: selectedRoomNomap?.id || selectedRoom?.id,
        zone: zone ? zone : 0,
        command: command,
      }),
    )
      .unwrap()
      .then(() => {
        setStatus("success");
        setTimeout(() => {
          setStatus(null);
        }, 2000);
      })
      .catch(() => {
        message.error(t("ErrorSetLight"), 5);
        setStatus("error");
        setTimeout(() => {
          setStatus(null);
        }, 2000);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        flexFlow: "column",
      }}
    >
      <Segmented
        size="large"
        value={value}
        style={{ width: "100%" }}
        options={[
          { label: <p style={{ margin: "0" }}>{t("comfort.SwitchOn")}</p>, value: "Allumé" },
          { label: <p style={{ margin: "0" }}>{t("comfort.SwitchOff")}</p>, value: "Éteint" },
        ]}
        onChange={(value) => {
          setValue(value);
          LightOnOff(value);
        }}
      />
      <div className={styles["SettingsUI__content"]}>
        <Button
          onClick={() => {
            dispatchLight("L_DW");
          }}
          type="ghost"
          style={{ width: "120px", padding: "0", marginRight: "8px" }}
          icon={
            <Glyph name="keyboard_arrow_down" style={{ fontSize: "24px", fontWeight: "bold" }} />
          }
        ></Button>
        <Button
          onClick={() => {
            dispatchLight("L_UP");
          }}
          type="ghost"
          style={{ width: "120px", padding: "0" }}
          icon={<Glyph name="keyboard_arrow_up" style={{ fontSize: "24px", fontWeight: "bold" }} />}
        ></Button>
      </div>
    </div>
  );
};

export default LightUpDown;
